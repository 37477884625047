let API_CONFIG = {
  production: {
    api: {
      baseURL: "http://api.snqis.gov.az/api/v1",
    },
    asan_login: {
      baseURL: "https://asanlogin.my.gov.az",
    },
  },
  development: {
    api: {
      baseURL: "https://dev.optima.az:8283/api/v1/",
    },
    asan_login: {
      baseURL: "https://asanloginpreprod.my.gov.az",
    },
  },
};
export default API_CONFIG[process.env.NODE_ENV];
